import logo from './logo.svg'
import './App.scss'

// Components
import MainLayout from './layouts/MainLayout'
import Home from './components/Home'
import ScrollToTopOnPageChange from './components/ScrollToTopOnPageChange'
import PopupContext from './context/popupContext'
import FAQ from './components/FAQ'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import { useEffect, useState, useContext } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'



function App() {
    const [currentPopup, setCurrentPopup] = useState({ content: '', isActive: false })
    const [currentLanguage, setCurrentLanguage] = useState('ru')

    return (
        <BrowserRouter>
            <ScrollToTopOnPageChange />
            <PopupContext.Provider value={{ currentPopup, setCurrentPopup }}>
                <div className="App">
                    <Routes>
                        <Route path="/" element={<MainLayout />}>
                            <Route index={true} element={<Home />} />
                            <Route element={<FAQ />} path="/FAQ"/>
                        </Route>
                    </Routes>
                </div>
            </PopupContext.Provider>
        </BrowserRouter>
    )
}

export default App
