import React from 'react'

import Instructions from './Instructions'
import Form from './Form'

import './Home.scss'
import './Header.scss'

import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import About from './About'

import headerImage from '../images/pages/1.png'

import 'swiper/css'
import 'swiper/css/bundle'



function HeaderV2() {
    

    return (
        <div className="block-background">
            <div className="block-wrapper" style={{  zIndex: 3 }}>
                <header>
                    <div className="header-left">
                        <h2 className='header-left__name-of-project'>Помощь по такому-то проекту</h2>

                        <h1>Помогите людям прямо сейчас</h1>
                        <Form />
                    </div>


        <div style={{backgroundImage: `url(${headerImage})`}} className='header-image'></div>
                    {/* <img src={headerImage} alt=""  /> */}
                </header>
            </div>

            
        </div>
    )
}

export default HeaderV2
