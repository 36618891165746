import React from 'react'
import { useState, useEffect } from 'react'

import importImages from '../utils/importImages'

import QRCode from 'react-qr-code'

import './Instructions.scss'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/bundle'

function Instructions() {
    const [currentVariant, setCurrentVariant] = useState('kaspi')
    const [currentImages, setCurrentImages] = useState([])

    const variantsList = [
        {
            id: 'kaspi',
            fullName: 'Kaspi Bank',
            steps: [
                'Выбираем «Платежи»',
                'затем «Все», далее «Благотворительность»',
                'Пишем в поиск «Красный Полумесяц»',
                'Вводим сумму и вносим пожертвование!',
            ],
            link: 'https://google.com',
        },
        { id: 'halyk', fullName: 'Halyk Bank', steps: ['Halyk Первый шаг', 'Halyk Второй шаг', 'Halyk Третий шаг'], link: 'https://google.com' },
        {
            id: 'forte',
            fullName: 'Forte Bank',
            steps: ['Forte Первый шаг', 'Forte Второй шаг', 'Forte Третий шаг', 'Forte Третий шаг'],
            link: 'https://google.com',
        },
    ]

    function setCurrentVariantHandler(newVariant) {
        console.log(newVariant)
        setCurrentVariant(newVariant)
    }

    useEffect(() => {
        const imagePaths = importImages(currentVariant)
        setCurrentImages(imagePaths)

        // console.log(imagePaths)
    }, [currentVariant])

    const currentVariantObj = variantsList.find((variant) => variant.id === currentVariant)

    return (
        <div className="instructions">
            <div className="instructions-left">
                <div className="instructions__controls">
                    {variantsList.map((el, id) => {
                        return (
                            <div
                                key={id}
                                className={`instructions-controls__button ${el.id === currentVariant ? 'instructions-controls__button--active' : ''}`}
                                onClick={(evt) => {
                                    setCurrentVariantHandler(el.id)
                                }}
                            >
                                {el.fullName}
                            </div>
                        )
                    })}
                </div>

                <div className="instructions__steps" style={{gridArea: 'stps1'}}>
                    <h4>Пошаговая инструкция:</h4>
                    <ul>
                        {currentVariantObj?.steps.map((step, index) => (
                            <li key={index}>{step}</li>
                        ))}
                    </ul>
                </div>

                <div style={{gridArea: 'stps2'}}>
                    <h4>Перейти в приложение:</h4>
                    <br />
                    <QRCode size={256} style={{ height: 'auto', maxWidth: '10rem', width: '100%' }} value={currentVariantObj?.link} viewBox={`0 0 256 256`} />
                </div>

                <div className="swiper-instructions--inner" style={{gridArea: 'img'}}>
                    <Swiper
                        // loop={true}
                        modules={[Autoplay, Pagination]}
                        pagination={{
                            enabled: true,
                            bulletClass: `swiper-pagination-bullet `,
                        }}
                        grabCursor={true}
                        centeredSlides={true}
                        className="swiper-instructions "
                    >
                        {currentImages.length > 0
                            ? currentImages.map((el, id) => {
                                  return (
                                      <SwiperSlide key={id}>
                                          <img src={el} alt="" width={342.33} height={597.12} />
                                      </SwiperSlide>
                                  )
                              })
                            : ''}
                    </Swiper>
                </div>
            </div>

            <div className="swiper-instructions--right">
                <Swiper
                    // loop={true}
                    modules={[Autoplay, Pagination]}
                    pagination={{
                        enabled: true,
                        bulletClass: `swiper-pagination-bullet `,
                    }}
                    grabCursor={true}
                    centeredSlides={true}
                    className="swiper-instructions "
                >
                    {currentImages.length > 0
                        ? currentImages.map((el, id) => {
                              return (
                                  <SwiperSlide key={id}>
                                      <img src={el} alt="" width={342.33} height={597.12} />
                                  </SwiperSlide>
                              )
                          })
                        : ''}
                </Swiper>
            </div>
        </div>
    )
}

export default Instructions
