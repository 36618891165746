import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'

// import i18next from 'i18next';
// import { I18nextProvider } from 'react-i18next';
// import en from './translations/en.json';

import { I18nextProvider } from 'react-i18next'

import i18n from './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <I18nextProvider i18n={i18n} >
        <App />
    </I18nextProvider>
)

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
