function importImages(imageMark) {
    function importAll(r) {
        // console.log(r)

        let images = {}
        r.keys().map((item, index) => {
            images[item.replace('./', '')] = r(item)
        })
        return images
    }

    const images = importAll(require.context(`../images/`, true, /\.(gif|jpe?g|svg|png)$/))


    const newImages = Object.keys(images).map((el) => images[el]).filter(el => el.includes(imageMark))

    return newImages
}

export default importImages