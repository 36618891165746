import React from 'react'

import { useState, useEffect } from 'react'

import AnimatedCounter from './AnimatedCounter'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import './You.scss'

import 'swiper/css'
import 'swiper/css/bundle'

import { v4 } from 'uuid'
import { useTranslation } from 'react-i18next'

function You() {
    const [imagePaths, setImagePaths] = useState([])

    const { t, i18n } = useTranslation('global')

    useEffect(() => {
        function importAll(r) {
            // console.log(r)

            let images = {}
            r.keys().map((item, index) => {
                images[item.replace('./', '')] = r(item)
            })
            return images
        }

        const images = importAll(require.context('../images/sliders/slider-we-work/', true, /\.(gif|jpe?g|svg|png)$/))

        const newImages = Object.keys(images).map((el) => images[el])

        // console.log(newImages)

        setImagePaths(newImages)
    }, [])

    return (
        <div className="your-donation-container">
            <div>
                <h2>{t('You.h2')}</h2>

                <p className="add-left-line">
                {t('You.text1')}
                </p>

                <h4 className="h4-flex">
                    <span>{t('You.text2')}</span>{' '}
                    <span>
                        {' '}
                        <AnimatedCounter end={100000} duration={2000} />{' '}
                    </span>{' '}
                    <span>{t('You.text3')}</span>
                </h4>
                <br />

                {imagePaths.length > 0 ? (
                    <Swiper
                        className="you-photos"
                        spaceBetween={32}
                        loop={true}
                        modules={[Autoplay, Pagination]}
                        pagination={{
                            enabled: true,
                            bulletClass: 'pagination-red',
                        }}
                        grabCursor={true}
                        breakpoints={{
                            700: {
                                slidesPerView: 2,
                            },
                            1300: {
                                slidesPerView: 3,
                            },
                            2000: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        {imagePaths.map((el) => {
                            return (
                                <SwiperSlide key={v4()}>
                                    <div style={{ backgroundImage: `url(${el})` }} alt="" className="swiper-image" />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default You
