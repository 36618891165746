import React from 'react';
import { useSpring, animated } from '@react-spring/web';

import useOnScreen from './useOnScreen.js';

const AnimatedCounter = ({ end, duration }) => {
    const [ref, isVisible] = useOnScreen({ threshold: 0 }); 

    // console.log(useOnScreen)

  // Определяем анимацию с начальным значением 0 и конечным значением 'end'
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: isVisible ? end : 0 },
    config: { duration: duration },
  });

  return (
    <animated.span className='animated-counter' ref={ref}>
      {/* Используем метод toLocaleString для форматирования числа с пробелами */}
      {number.to((n) => Math.ceil(n).toLocaleString())}
    </animated.span>
  );
};

export default AnimatedCounter;
