import React from 'react'

import { Link } from 'react-router-dom'

import './Footer.scss'

function Footer() {
    return (
        <footer>
            <div className="footer-section">
                <h4>Компания</h4>
                <ul>
                    <a href="/about">О нас</a>

                    <a href="/careers">Вакансии</a>

                    <a href="/contact">Контакты</a>

                    <a href="/blog">Блог</a>
                </ul>
            </div>

            <div className="footer-section">
                <h4>Поддержка</h4>
                <ul>
                    <a href="/faq">Часто задаваемые вопросы</a>

                    <a href="/support">Техническая поддержка</a>

                    <a href="/return-policy">Политика возврата</a>

                    <a href="/shipping-info">Информация о доставке</a>
                </ul>
            </div>

            <div className="footer-section">
                <h4>Продукты</h4>
                <ul>
                    <a href="/products">Все продукты</a>

                    <a href="/new-arrivals">Новые поступления</a>

                    <a href="/best-sellers">Бестселлеры</a>

                    <a href="/offers">Специальные предложения</a>
                </ul>
            </div>

            <div className="footer-section">
                <h4>Юридическая информация</h4>
                <ul>
                    <a href="/terms">Условия использования</a>

                    <a href="/privacy">Политика конфиденциальности</a>

                    <a href="/cookie-policy">Политика использования cookie</a>

                    <a href="/disclaimer">Отказ от ответственности</a>
                </ul>
            </div>

            <div className="footer-section">
                <h4>Наши магазины</h4>
                <ul>
                    <a href="/store-locator">Поиск магазинов</a>

                    <a href="/store1">Магазин 1</a>

                    <a href="/store2">Магазин 2</a>

                    <a href="/store3">Магазин 3</a>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
