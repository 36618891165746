import React from 'react'

import Header from './Header'
import HeaderV2 from './HeaderV2'
import Instructions from './Instructions'

import './Home.scss'

import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper/modules'

import About from './About'

import 'swiper/css'
import 'swiper/css/bundle'

import { useTranslation } from 'react-i18next';

function Home() {
    const { t, i18n } = useTranslation('global');

    if (!i18n.isInitialized) {
        return <div>Loading...</div>; // Показываем индикатор загрузки
    }


    return (
        <>
           
            <HeaderV2/>
            {/* <h4>{t('Home.header')}</h4> */}

            <div className="block-background">
                <div className="block-wrapper">
                    <About />
                </div>
            </div>

            <div className="block-background" >
                <div className="block-wrapper">

                    <Instructions/>

                </div>
            </div>
        </>
    )
}

export default Home
