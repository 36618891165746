import React from 'react'

import { NavLink } from 'react-router-dom'
import './Menu.scss'

import { useContext, useEffect, useState } from 'react'
import PopupContext from '../context/popupContext'

import { HashLink } from 'react-router-hash-link'

import logoRuDark from '../images/logo-ru-dark.svg'

import { useTranslation } from 'react-i18next'

function Menu() {
    const { t, i18n } = useTranslation('global')

    const { currentPopup, setCurrentPopup } = useContext(PopupContext)

    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerWidth >= 768) {
                if (window.scrollY > 0) {
                    setIsSticky(true)
                } else {
                    setIsSticky(false)
                }
            } else {
                setIsSticky(false)
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    const handleBackdropClick = () => {
        // console.log('123')

        setCurrentPopup({ content: currentPopup.content, isActive: false })
        setTimeout(() => {
            setCurrentPopup({ content: '', isActive: false })
        }, 300)
    }

    const changeLang = (lang) => {
        i18n.changeLanguage(lang)
    }

    // console.log(i18n.language)

    return (
        <>
            {/* <div className="menu-fake"></div> */}
            <nav className={isSticky ? 'sticky-menu' : ''}>
                <NavLink
                    className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                    to="/"
                    end
                    onClick={(evt) => {
                        handleBackdropClick()
                    }}
                >
                    <img src={logoRuDark} alt="Company logo" className="menu__company-logo" />
                </NavLink>

                <div className="main-links">
                    <NavLink
                        className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                        to="/"
                        end
                        onClick={(evt) => {
                            handleBackdropClick()
                        }}
                    >
                        Главная
                    </NavLink>

                    <HashLink
                        // className={({ isActive }) => (isActive ? 'activeLink' : 'link')}

                        className="link"
                        to="/#what-we-do"
                        // end
                        onClick={(evt) => {
                            handleBackdropClick()
                        }}
                    >
                        Пожертвования в действии
                    </HashLink>

                    <NavLink to="https://redcrescent.kz/partner/">Корпоративным партнёрам</NavLink>

                    <NavLink
                        to="/FAQ"
                        className={({ isActive }) => (isActive ? 'activeLink' : 'link')}
                        onClick={(evt) => {
                            handleBackdropClick()
                        }}
                    >
                        FAQ
                    </NavLink>

                    <NavLink to="https://redcrescent.kz/contact-us/">Контакты</NavLink>
                </div>

                <div className="languages-list">
                    <button
                        onClick={(evt) => {
                            evt.preventDefault()
                            changeLang('ru')
                            handleBackdropClick()
                        }}
                        className={`language-list__item ${i18n.language === 'ru' ? 'language-list__item--active' : ''}`}
                    >
                        ru
                    </button>
                    <button
                        onClick={(evt) => {
                            evt.preventDefault()
                            changeLang('kz')
                            handleBackdropClick()
                        }}
                        className={`language-list__item ${i18n.language === 'kz' ? 'language-list__item--active' : ''}`}
                    >
                        kz
                    </button>
                    <button
                        onClick={(evt) => {
                            evt.preventDefault()
                            changeLang('en')
                            handleBackdropClick()
                        }}
                        className={`language-list__item ${i18n.language === 'en' ? 'language-list__item--active' : ''}`}
                    >
                        en
                    </button>
                </div>
            </nav>
        </>
    )
}

export default Menu
