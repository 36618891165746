import React from 'react'

import image from '../images/sliders/slider-home/0.jpeg'

import './About.scss'

import Beneficiaries from './Beneficiaries'
import You from './You'

import { useTranslation } from 'react-i18next'

function About() {
    const { t, i18n } = useTranslation('global')

    return (
        <>
            <div className="about-company" id="what-we-do">
                <div className="about-company__left ">
                    <h2>{t('About.header2')}</h2>
                    <p className="add-left-line">{t('About.text1')}</p>
                    <br />

                    <h4>{t('About.h4--1')}</h4>
                    <p className="add-left-line">
                    {t('About.text2')}
                    </p>

                    <h4> {t('About.h4--2')}</h4>
                    <ul>
                        
                        <li>{t('About.ul1__li1')}</li>
                        <li>{t('About.ul1__li2')}</li>
                        <li>{t('About.ul1__li3')}</li>
                        <li>{t('About.ul1__li4')}</li>
                        
                    </ul>
                </div>

                <div className="image-with-descrpiption__image" style={{ backgroundImage: `url(${image})` }}></div>
            </div>

            {/* <Beneficiaries/> */}

            <You />
        </>
    )
}

export default About
