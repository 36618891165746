import React from 'react'

import { useState } from 'react'

import './Form.scss'

function Form() {
    const [format, setFormat] = useState('regular')
    const [sum, setSum] = useState(3000)

    const [otherStatus, setOtherStatus] = useState(false)

    const sumSample = [1000, 3000, 10000]

    return (
        <form>
            <div className="format-control">
                <button
                    className={format === 'regular' ? 'active' : 'inactive'}
                    onClick={(evt) => {
                        evt.preventDefault()

                        setFormat('regular')
                    }}
                >
                    В месяц
                </button>
                <button
                    className={format === 'single' ? 'active' : 'inactive'}
                    onClick={(evt) => {
                        evt.preventDefault()

                        setFormat('single')
                    }}
                >
                    Единоразово
                </button>
            </div>
            <div className="form-payment">
                <div>
                    <h2 className="form-h2">Сколько вы хотите пожертвовать</h2>
                    <div className="sum-control">
                        {sumSample.map((el,id) => {
                            return (
                                <button key={id}
                                    onClick={(evt) => {
                                        evt.preventDefault()

                                        setOtherStatus(false)

                                        setSum(el)

                                        console.log(el.toLocaleString())
                                    }}
                                    className={el === sum ? 'active' : 'inactive'}
                                >
                                    {el.toLocaleString()} тг
                                </button>
                            )
                        })}

                        <button
                            onClick={(evt) => {
                                evt.preventDefault()

                                setOtherStatus(true)
                            }}
                        >
                            Другая сумма
                        </button>
                    </div>
                </div>
                {otherStatus === true ? (
                    <div>
                        <h2 className="form-h2">Хотите ввести другую сумму?</h2>
                        <input
                            type="number"
                            className="form-payment__input"
                            onChange={(evt) => {
                                setSum(evt.target.value)
                            }}
                        />
                    </div>
                ) : (
                    ''
                )}
                <button
                    onClick={(evt) => {
                        evt.preventDefault()
                    }}
                    className="pay-button"
                >
                    Оплатить
                </button>
            </div>
        </form>
    )
}

export default Form
